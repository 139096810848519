<script lang="ts" setup>
const { t } = useI18n()
const localePath = useLocalePath()
const route = useRoute()
const orderStore = useOrder()
const { items, delivery, shipping, fee, grandTotal } = storeToRefs(orderStore)

const steps = [
  null,
  t('pages.checkout.cart'),
  t('pages.checkout.account'),
  t('pages.checkout.shipping'),
  t('pages.checkout.payment'),
  t('pages.checkout.confirmation'),
]

const stepMap = {
  [localePath('/checkout/cart')]: 1,
  [localePath('/checkout/account')]: 2,
  [localePath('/checkout/shipping')]: 3,
  [localePath('/checkout/guest')]: 3,
  [localePath('/checkout/payment')]: 4,
  [localePath('/checkout/success')]: 5,
  [localePath('/checkout/failure')]: 5,
  [localePath('/checkout/confirmation')]: 5,
}

const activeStep = computed(() => stepMap[route.path])
</script>

<template>
  <div>
    <header class="grid h-32 grid-rows-2 flex-wrap gap-3 bg-gray-800">
      <nav class="flex items-end gap-6 px-2 pt-3 md:px-6">
        <NuxtLink
          class="flex h-full shrink-0 items-center"
          :to="localePath('/')"
        >
          <NuxtImg
            src="https://cdn.jagofon.com/logo/logo-green.svg"
            class="h-full object-contain object-left"
          />
        </NuxtLink>
      </nav>

      <div class="flex items-center bg-white px-2 md:px-6">
        <div class="container">
          <UProgress :max="steps.length - 1" :value="activeStep" />
        </div>
      </div>
    </header>

    <div class="px-2 md:px-6">
      <div class="container flex flex-wrap gap-6">
        <main class="w-full flex-initial md:w-[calc(66.666%-1.5rem)]">
          <slot />
        </main>

        <aside class="w-full shrink-0 pb-6 md:w-1/3 md:pb-0">
          <div id="sidebar" />
          <div v-if="activeStep !== 1 && activeStep !== 5" class="space-y-6">
            <UCard>
              <div v-if="items !== null" class="divide-y">
                <div
                  v-for="item in items"
                  :key="item.product.id"
                  class="py-3 first:pt-0"
                >
                  <div class="flex items-start">
                    <div class="size-12">
                      <NuxtImg
                        :src="item.product.image"
                        class="size-full object-contain"
                      />
                    </div>
                    <div class="pl-1 text-sm">
                      <p class="font-medium">{{ item.product.name }}</p>
                      <p>
                        {{ $t('global.condition') }}:
                        {{ item.product.condition }}
                      </p>
                    </div>
                  </div>
                  <div class="mt-2 text-xs">
                    <div class="flex justify-between">
                      <p>{{ $t('global.product-price') }}</p>
                      <p>{{ $n(item.product.price, 'currency') }}</p>
                    </div>
                    <div class="flex justify-between">
                      <p>{{ item.warranty.term }}</p>
                      <p>{{ $n(item.warrantyPrice, 'currency') }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="shipping" class="border-t py-3 text-xs">
                <div class="flex justify-between">
                  <p>
                    {{ $t('pages.checkout.shipping-fee', [shipping.method]) }}
                  </p>
                  <p>{{ $n(shipping.price, 'currency') }}</p>
                </div>
                <div class="flex justify-between">
                  <p>{{ $t('pages.checkout.shipping-insurance') }}</p>
                  <p>{{ $n(shipping.insurancePrice, 'currency') }}</p>
                </div>
              </div>
              <div v-if="activeStep > 2" class="border-t py-3">
                <PagesOrderVoucher />
              </div>
              <div
                v-if="fee"
                class="flex justify-between border-t py-3 text-xs"
              >
                <p>{{ fee.name }}</p>
                <p>{{ $n(fee.amount, 'currency') }}</p>
              </div>
              <div class="flex justify-between border-t pt-3 font-medium">
                <p>{{ $t('global.total') }}</p>
                <p>{{ $n(grandTotal, 'currency') }}</p>
              </div>
            </UCard>
            <UCard v-if="delivery">
              <div class="flex items-center gap-1 text-sm">
                <p class="font-medium">
                  {{ delivery.name }} ({{ delivery.phone }})
                </p>
              </div>
              <p class="mt-2 text-xs">
                <b>{{ delivery.address }}</b>
                <br />
                {{ delivery.subdistrict }}, {{ delivery.district }},
                {{ delivery.city }}
                <br />
                {{ delivery.province }}, {{ delivery.postCode }}
              </p>
            </UCard>
            <div id="continue-button" />
          </div>
          <div class="mt-2 hidden space-y-1 text-xs text-gray-400 md:block">
            <p>
              {{ $t('pages.order.guarantee') }}
            </p>
            <i18n-t tag="p" keypath="pages.order.terms">
              <template #terms>
                <NuxtLinkLocale to="/policy/terms-of-service" class="underline">
                  {{ $t('global.terms-of-service') }}
                </NuxtLinkLocale>
              </template>
              <template #privacy>
                <NuxtLinkLocale to="/policy/privacy-policy" class="underline">
                  {{ $t('navigation.privacy-policy') }}
                </NuxtLinkLocale>
              </template>
            </i18n-t>
          </div>
        </aside>
      </div>
    </div>

    <UNotifications />
    <UModals />
  </div>
</template>
